<template>
  <div>
    <vue-base-dialog
      :dialogConfig="dialogConfig"
      class="selfCreateDialog"
      ref="childDialog"
     
    >
     <!-- @close="parentModalFunc" -->
      <div class="dialog-content">
        <div class="center-form-box">
          <div class="form-group">
            <div class="form-label">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</div>
            <div :class="[nameError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeName($event)"
                  v-model="name"
                  class="form-input"
                  placeholder="请输入姓名"
                />
              </div>
              <div v-if="nameError" class="error-info">{{ nameError }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">邮&nbsp;箱&nbsp;地&nbsp;址：</div>
            <div :class="[emailError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeEmail($event)"
                  v-model="email"
                  class="form-input"
                  placeholder="请输入邮箱"
                />
              </div>
              <div v-if="emailError" class="error-info">{{ emailError }}</div>
            </div>
          </div>
         
          <div class="confirm-button" @click="sumbitForm">确认修改</div>
        </div>

      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import services from "../../../config/services";
import { mapActions, mapState } from "vuex";
export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
  },
  components: {
    VueBaseDialog,
  },
  data() {
    return {
      services: services,
      email: '',
      emailError: '',
      name: '',
      nameError: '',
    };
  },
  mounted() {
    this.name = this.appsUserInfo.name ? this.appsUserInfo.name : ''
    this.email = this.appsUserInfo.email ? this.appsUserInfo.email : ''
  },
  computed: {
    ...mapState({ appsUserInfo: (state) => state.appsLogin.appsUserInfo }),
  },
  methods: {
    parentModalFunc() {
      this.$emit("refreshData");
    },
    backUserCenter() {
      this.$refs.childDialog.close();
      this.$emit("refreshData");
    },
    async sumbitForm() {
      let params = {};
      let that = this;
      if (this.name) {
        params.name = this.name;
        this.nameError = "";
      } else {
        this.nameError = "请输入姓名";
        return false;
      }
      if (this.email) {
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.email)) {
          that.emailError = "";
          params.email = that.email;
        } else {
          that.emailError = "请输入正确的邮箱地址";
          return false
        }
      } else {
        that.emailError = "请输入邮箱";
        return false;
      }
      
      if (this.nameError || this.emailError) {
        return false;
      } else {
        that.metodModify({
          _method: 'put',
          ...params
        })
      }
    },
    // 修改用户信息
    metodModify(params) {
      let that = this;
      services.postAppsEditInfo(params).then((res) => {
        if (res.code === "0000") {
          that.backUserCenter()
        } else {
          if (res.errors) {
            that.nameError = res.errors.name;
            that.emailError = res.errors.email;
          }
          that.$message.error(res.message ? res.message : "绑定失败");
        }
      });
    },
    changeEmail(e) {
      let that = this;
      if (e.target.value) {
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(e.target.value)) {
          that.emailError = "";
        } else {
          that.emailError = "请输入正确的邮箱地址";
        }
      } else {
        that.emailError = "请输入邮箱";
      }
    },
    changeName(e) {
      if (e.target.value) {
        this.nameError = "";
      } else {
        this.nameError = "请输入姓名";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 40px;
  width: 600px;
}
.button {
  margin-top: 10px;
}
.button:hover {
  background: #134bce;
}
.center-form-box {
  margin: 30px auto 0;
  width: 350px;
  box-sizing: border-box;
  .form-group {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    .form-control {
      flex: 1;
      display: flex;
      position: relative;
      border-bottom: 1px solid #e5e5e5;
      border-top: none;
      border-left: none;
      border-right: none;
      .input-box {
        flex: 1;
        input {
          display: inline-block;
          width: 100%;
          border-radius: 0;
          border: none;
          height: 40px;
          line-height: 40px;
          padding: 5px 0;
          box-sizing: border-box;
          color: #333;
          font-size: 14px;
        }
      }
      .login-code {
        width: 86px;
        height: 40px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
      }
      .login-sms {
        color: #2462f5;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        margin-left: 10px;
        &.disabled {
          color: #969696;
        }
      }

      .error-info {
        position: absolute;
        bottom: -18px;
        font-size: 12px;
        color: #f73b3b;
        left: 0;
      }
      &.error {
        border-color: #f73b3b;
      }
    }
    .form-label {
      width: 84px;
      color: #333333;
      line-height: 40px;
    }
  }

  .confirm-button {
    padding: 0 20px;
    height: 32px;
    display: inline-block;
    background: #2462f5;
    border-radius: 3px;
    font-size: 14px;
    color: #ecedff;
    line-height: 32px;
    margin: 40px auto 20px;
    cursor: pointer;
  }
}
.complete-form-box {
  margin: 30px auto 0;
  box-sizing: border-box;
  .confirm-image {
    width: 50px;
    margin: 0 auto 30px;
  }
  .new-phone {
    color: #8b8b8b;
    font-size: 14px;
    margin: 0 auto;
  }
  .confirm-button {
    padding: 0 20px;
    height: 32px;
    display: inline-block;
    background: #2462f5;
    border-radius: 3px;
    font-size: 14px;
    color: #ecedff;
    line-height: 32px;
    margin: 50px auto 20px;
    cursor: pointer;
  }
}
</style>